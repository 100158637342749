import axios from 'axios';
import { BASE_URL } from './configs';

export const checkAdminApi = async (token) => {
    try {
        const response = await axios.get(`${BASE_URL}/api/v1/administrator/check-admin`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};
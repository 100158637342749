import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from 'js-cookie'; // Import thư viện js-cookie
import { login } from '../api/login';

export const loginThunk = createAsyncThunk(
  'login',
  async (credentials, thunkAPI) => {
    try {
      const response = await login(credentials);
      Cookies.set('token', response.data.token, { expires: 365 }); // Lưu token đăng nhập vào cookies với thời gian sống là 365 ngày
      return response.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue({ message: 'Something went wrong' });
      }
    }
  }
);

const loginSlice = createSlice({
  name: 'login',
  initialState: {
    user: null,
    token: Cookies.get('token') || null, // Lấy token từ cookies
    isLoading: false,
    error: null,
    isLoggedIn: !!Cookies.get('token'), // Kiểm tra xem token có tồn tại trong cookies không
  },
  reducers: {
    setCredentials: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.isLoggedIn = true;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginThunk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(loginThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload.user;
        state.token = action.payload.token;
        state.isLoggedIn = true;
      })
      .addCase(loginThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { setCredentials } = loginSlice.actions;

export default loginSlice.reducer;
import axios from 'axios';
import { BASE_URL } from './configs';

export const login = async (credentials) => {
  try {
    const response = await axios.post(`${BASE_URL}/login`, credentials);
    return response;
  } catch (error) {
    throw error;
  }
};
import axios from 'axios';
import Cookies from 'js-cookie';
import { BASE_URL } from './configs';

// Hàm để lấy mã thông báo từ cookies
const getToken = () => {
    return Cookies.get('token'); // Giả sử mã thông báo được lưu trong cookies với key là 'token'
};

// Cấu hình axios để tự động thêm mã thông báo vào mỗi yêu cầu
axios.interceptors.request.use(config => {
    const token = getToken();
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, error => {
    return Promise.reject(error);
});

// Lấy danh sách transaction với phân trang và lọc theo idUser
export const getTransactionsAPI = async (page = 1, limit = 10, idUser = '') => {
    const response = await axios.get(`${BASE_URL}/api/v1/administrator/transactions`, {
        params: { page, limit, idUser }
    });
    return response.data;
};

export const deleteTransactionAPI = async (id) => {
    await axios.delete(`${BASE_URL}/api/v1/administrator/transactions/${id}`);
    return id;
};

export const updateTransactionStatusAPI = async (id, status) => {
    const response = await axios.patch(`${BASE_URL}/api/v1/administrator/transactions/${id}/status`, { status });
    return response.data;
};
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getTransactionsAPI, deleteTransactionAPI, updateTransactionStatusAPI } from '../api/managerTransactionApi';

// Cập nhật fetchTransactions để nhận thêm các tham số page và idUser
export const fetchTransactions = createAsyncThunk(
    'adminTransactions/fetchTransactions',
    async ({ page = 1, idUser = '' }, { rejectWithValue }) => {
        try {
            return await getTransactionsAPI(page, 10, idUser); // Gửi page và idUser lên API
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const deleteTransaction = createAsyncThunk(
    'adminTransactions/deleteTransaction',
    async (id, { rejectWithValue }) => {
        try {
            return await deleteTransactionAPI(id);
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const updateTransactionStatus = createAsyncThunk(
    'adminTransactions/updateTransactionStatus',
    async ({ id, status }, { rejectWithValue }) => {
        try {
            return await updateTransactionStatusAPI(id, status);
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const adminTransactionSlice = createSlice({
    name: 'adminTransactions',
    initialState: {
        transactions: [],
        loading: false,
        error: null,
        totalCredit: 0, // Tổng giá trị giao dịch
        totalPages: 1,  // Tổng số trang
        currentPage: 1, // Trang hiện tại
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchTransactions.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchTransactions.fulfilled, (state, action) => {
                state.loading = false;
                state.transactions = action.payload.transactions;
                state.totalCredit = action.payload.totalCredit; // Cập nhật tổng giá trị giao dịch
                state.totalPages = action.payload.totalPages;   // Cập nhật tổng số trang
                state.currentPage = action.payload.currentPage; // Cập nhật trang hiện tại
            })
            .addCase(fetchTransactions.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload ? action.payload.message : action.error.message;
            })
            .addCase(deleteTransaction.fulfilled, (state, action) => {
                state.transactions = state.transactions.filter(transaction => transaction._id !== action.payload);
            })
            .addCase(updateTransactionStatus.fulfilled, (state, action) => {
                const index = state.transactions.findIndex(transaction => transaction._id === action.payload._id);
                if (index !== -1) {
                    state.transactions[index] = action.payload;
                }
            });
    },
});

export default adminTransactionSlice.reducer;
import React, { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { checkAdmin } from '../../store/checkAdminSlice';

const AdminRouter = () => {
    const dispatch = useDispatch();
    const { isAdmin, status } = useSelector((state) => state.admin);
    const token = Cookies.get('token');

    useEffect(() => {
        if (token) {
            dispatch(checkAdmin());
        }
    }, [dispatch, token]);

    if (status === 'loading') {
        return <p>Loading...</p>;
    }

    if (status === 'succeeded' && isAdmin) {
        return <Outlet />;
    }

    if (status === 'failed' || !token) {
        return <Navigate to="/login" />;
    }

    // Điều kiện kiểm tra nếu không phải admin
    if (status === 'succeeded' && !isAdmin) {
        console.log('Not an admin, redirecting to login');
        return <Navigate to="/login" />;
    }

    return null; // Tránh render bất kỳ thứ gì khi vẫn đang chờ đợi
};

export default AdminRouter;
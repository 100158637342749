import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { checkAdminApi } from '../api/checkAdminApi';
import Cookies from 'js-cookie';

export const checkAdmin = createAsyncThunk(
    'admin/checkAdmin',
    async (_, { rejectWithValue }) => {
        try {
            const token = Cookies.get('token'); // Lấy token từ cookies
            if (!token) {
                throw new Error('Token không tồn tại');
            }
            const response = await checkAdminApi(token);
            return response;
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);

const adminSlice = createSlice({
    name: 'admin',
    initialState: {
        isAdmin: false,
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(checkAdmin.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(checkAdmin.fulfilled, (state, action) => {
                if (action.payload.message === 'Bạn là admin') {
                    state.isAdmin = true;
                }
                state.status = 'succeeded';
            })
            .addCase(checkAdmin.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
                state.isAdmin = false;
            });
    },
});

export default adminSlice.reducer;
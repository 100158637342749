import React, { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import { getUserThunk } from './store/profileSlice';

import MainLayout from './components/MainLayout';

import PrivateRoute from './components/PrivateRoute';
import AdminRouter from './components/administrator/AdminRouter';

const Error = lazy(() => import('./components/Error'));
const LoginForm = lazy(() => import('./components/LoginForm'));
const Profile = lazy(() => import('./components/Profile'));

// Administrator
const AdminDashboard = lazy(() => import('./components/administrator/AdminDashboard'));
const ManagerTransaction = lazy(() => import('./components/administrator/ManagerTransaction'));
const CreateGiftForm = lazy(() => import('./components/administrator/CreateGiftForm'));
const ManagerGiftVoucher = lazy(() => import('./components/administrator/ManagerGiftVoucher'));
const ManagerGiftList = lazy(() => import('./components/administrator/ManagerGiftList'));
const ManagerGiftListFlashSale = lazy(() => import('./components/administrator/ManagerGiftListFlashSale'));
const ManagerVoucherLazada = lazy(() => import('./components/administrator/voucherlazada/ManagerVoucher'));
const CreateVoucherLazada = lazy(() => import('./components/administrator/voucherlazada/CreateVoucher'));
const EditVoucherLazada = lazy(() => import('./components/administrator/voucherlazada/EditVoucher'));
const ManagerVoucherShopee = lazy(() => import('./components/administrator/vouchershopee/ManagerVoucher'));
const CreateVoucherShopee = lazy(() => import('./components/administrator/vouchershopee/CreateVoucher'));
const EditVoucherShopee = lazy(() => import('./components/administrator/vouchershopee/EditVoucher'));
const ManagerVoucherTikTok = lazy(() => import('./components/administrator/vouchertiktok/ManagerVoucher'));
const CreateVoucherTikTok = lazy(() => import('./components/administrator/vouchertiktok/CreateVoucher'));
const EditVoucherTikTok = lazy(() => import('./components/administrator/vouchertiktok/EditVoucher'));
const ManagerPost = lazy(() => import('./components/administrator/post/ManagerPost'));
const CreatePost = lazy(() => import('./components/administrator/post/CreatePost'));
const EditPost = lazy(() => import('./components/administrator/post/EditPost'));
const ManagerSendEmailCampaign = lazy(() => import('./components/administrator/ManagerSendEmailCampaign'));
const ManagerNotifications = lazy(() => import('./components/administrator/ManagerNotifications'));
const ManagerPopupImage = lazy(() => import('./components/administrator/ManagerPopupImage'));
const ManagerUser = lazy(() => import('./components/administrator/ManagerUser'));
const ManagerCashback = lazy(() => import('./components/administrator/ManagerCashback'));
const ManagerConversionTikTok = lazy(() => import('./components/administrator/ManagerConversionTikTok'));
const ManagerBonus = lazy(() => import('./components/administrator/ManagerBonus'));
const ManagerDealShopee = lazy(() => import('./components/administrator/ManagerDealShopee'));
const ManagerUrlsSitemap = lazy(() => import('./components/administrator/ManagerUrlsSitemap'));

const App = () => {
    const dispatch = useDispatch();
    const token = Cookies.get('token'); // Lấy token từ cookies

    useEffect(() => {
        if (token) {
            dispatch(getUserThunk());
        }
    }, [dispatch, token]);

    return (
        <BrowserRouter>
            <MainLayout>
                <Routes>
                    <Route path="/login" element={<Suspense><LoginForm /></Suspense>} />
                    <Route element={<PrivateRoute />}>
                        <Route path="/profile" element={<Suspense><Profile /></Suspense>} />
                        <Route element={<AdminRouter />}>
                            <Route path="/" element={<Suspense><AdminDashboard /></Suspense>} />
                            <Route path="/transactions" element={<Suspense><ManagerTransaction /></Suspense>} />
                            <Route path="/gifts" element={<Suspense><ManagerGiftList /></Suspense>} />
                            <Route path="/gifts/create" element={<Suspense><CreateGiftForm /></Suspense>} />
                            <Route path="/gifts-flashsale" element={<Suspense><ManagerGiftListFlashSale /></Suspense>} />
                            <Route path="/gift-voucher" element={<Suspense><ManagerGiftVoucher /></Suspense>} />
                            <Route path="voucher-lazada" element={<Suspense><ManagerVoucherLazada /></Suspense>} />
                            <Route path="voucher-lazada/create" element={<Suspense><CreateVoucherLazada /></Suspense>} />
                            <Route path="voucher-lazada/edit/:id" element={<Suspense><EditVoucherLazada /></Suspense>} />
                            <Route path="voucher-shopee" element={<Suspense><ManagerVoucherShopee /></Suspense>} />
                            <Route path="voucher-shopee/create" element={<Suspense><CreateVoucherShopee /></Suspense>} />
                            <Route path="voucher-shopee/edit/:id" element={<Suspense><EditVoucherShopee /></Suspense>} />
                            <Route path="voucher-tiktok" element={<Suspense><ManagerVoucherTikTok /></Suspense>} />
                            <Route path="voucher-tiktok/create" element={<Suspense><CreateVoucherTikTok /></Suspense>} />
                            <Route path="voucher-tiktok/edit/:id" element={<Suspense><EditVoucherTikTok /></Suspense>} />
                            <Route path="post" element={<Suspense><ManagerPost /></Suspense>} />
                            <Route path="post/create" element={<Suspense><CreatePost /></Suspense>} />
                            <Route path="post/:id" element={<Suspense><EditPost /></Suspense>} />
                            <Route path="send-email" element={<Suspense><ManagerSendEmailCampaign /></Suspense>} />
                            <Route path="notification" element={<Suspense><ManagerNotifications /></Suspense>} />
                            <Route path="popup-image" element={<Suspense><ManagerPopupImage /></Suspense>} />
                            <Route path="user" element={<Suspense><ManagerUser /></Suspense>} />
                            <Route path="cashback" element={<Suspense><ManagerCashback /></Suspense>} />
                            <Route path="conversiontiktok" element={<Suspense><ManagerConversionTikTok /></Suspense>} />
                            <Route path="bonus" element={<Suspense><ManagerBonus /></Suspense>} />
                            <Route path="deal-shopee" element={<Suspense><ManagerDealShopee /></Suspense>} />
                            <Route path="sitemap" element={<Suspense><ManagerUrlsSitemap /></Suspense>} />
                        </Route>
                    </Route> 
                    <Route path="*" element={<Suspense><Error /></Suspense>} />         
                </Routes>
            </MainLayout>
        </BrowserRouter>
    );
};

export default App;
import React from 'react';

const Footer = () => {
  return (
    <footer className="p-4 w-full border-t shadow-sm bg-white mb-12">
      <div className="px-3 max-w-[768px] w-full mx-auto flex flex-col items-center sm:flex-row sm:items-start sm:justify-between">
        <p className="text-sm text-center sm:text-right">Copyright &copy; 2024 by Tui3Gang.Com</p>
      </div>
    </footer>
  );
};

export default Footer;
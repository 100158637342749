import { configureStore } from '@reduxjs/toolkit';
import loginReducer from './loginSlice';
import profileReducer from './profileSlice';
//Slice of Administrator
import checkAdminReducer from './checkAdminSlice';
import adminTransaction from './managerTransactionSlice';
import giftReducer from './managerGiftSlice';

const store = configureStore({
  reducer: {
    login: loginReducer,
    profile: profileReducer,
    admin: checkAdminReducer,
    adminTransactions: adminTransaction,
    gifts: giftReducer,
  },
});

export default store;
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { createGiftApi } from '../api/managerGiftApi';

export const createGift = createAsyncThunk(
    'gifts/createGift',
    async (giftData, { rejectWithValue }) => {
        try {
            const response = await createGiftApi(giftData);
            return response;
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);

const giftSlice = createSlice({
    name: 'gifts',
    initialState: {
        giftList: [],
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(createGift.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createGift.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.giftList.push(action.payload);
            })
            .addCase(createGift.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    },
});

export default giftSlice.reducer;
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from 'js-cookie'; // Import thư viện js-cookie
import { getUser, updateUser } from '../api/profile';

export const getUserThunk = createAsyncThunk(
  'fetchUser',
  async (_, { getState, rejectWithValue }) => {
    try {
      const token = getState().profile.token || Cookies.get('token'); // Lấy token từ Redux store hoặc cookies
      const response = await getUser(token);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateUserThunk = createAsyncThunk(
  'updateUser',
  async (userData, thunkAPI) => {
    try {
      const token = Cookies.get('token'); // Lấy token từ cookies
      const response = await updateUser(userData, token);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    user: null,
    token: Cookies.get('token') || null, // Lấy token từ cookies khi khởi tạo
    isLoading: false,
    isLoggedIn: !!Cookies.get('token'), // Đặt trạng thái đăng nhập dựa trên token trong cookies
    error: null,
  },
  reducers: {
    logout: (state) => {
      Cookies.remove('token'); // Xóa token khỏi cookies
      state.user = null;
      state.token = null;
      state.isLoggedIn = false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserThunk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getUserThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload;
        state.isLoggedIn = true;
      })
      .addCase(getUserThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        state.isLoggedIn = false;
        state.token = null;
        Cookies.remove('token'); // Xóa token khỏi cookies nếu lấy thông tin người dùng thất bại
      })
      .addCase(updateUserThunk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateUserThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload;
      })
      .addCase(updateUserThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { logout } = profileSlice.actions;

export default profileSlice.reducer;
import axios from 'axios';
import { BASE_URL } from './configs';
import Cookies from 'js-cookie';

export const fetchGifts = async () => {
    const token = Cookies.get('token');
    const response = await axios.get(`${BASE_URL}/api/v1/administrator/gifts/get`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
};

export const createGiftApi = async (giftData) => {
    const token = Cookies.get('token');
    const response = await axios.post(`${BASE_URL}/api/v1/administrator/gifts/create`, giftData, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
};

export const updateGift = async (id, giftData) => {
    const token = Cookies.get('token');
    const response = await axios.put(`${BASE_URL}/api/v1/administrator/gifts/update/${id}`, giftData, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
};

export const deleteGift = async (id) => {
    const token = Cookies.get('token');
    const response = await axios.delete(`${BASE_URL}/api/v1/administrator/gifts/delete/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
};